export function cancelableFetch(reqInfo, reqInit) {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const cancel = abortController.abort.bind(abortController);

    const wrapResult = function (result) {
        if (result instanceof Promise) {
            const promise = result;
            promise.then = function (onFulfilled, onRejected) {
                const nativeThenResult = Object.getPrototypeOf(this).then.call(this, onFulfilled, onRejected);
                return wrapResult(nativeThenResult);
            }
            promise.cancel = cancel;
        }
        return result;
    }

    const req = window.fetch(reqInfo, Object.assign({signal: signal}, reqInit));
    return wrapResult(req);
}
