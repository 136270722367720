import { cancelableFetch } from "./utils/_fetch";

window.initMap = function(){

    //Map config
    var center = {lat: 46.71109, lng:1.7191036};
    var zoom = 6;

    if(window.matchMedia("(max-width: 720px)").matches || window.matchMedia("(max-height: 768px)").matches){
        zoom = 5;
    }

    var map = new google.maps.Map(document.getElementById('map'), {
        center: center,
        zoom: zoom,
        minZoom: zoom,
        disableDefaultUI: true, // a way to quickly hide all controls,
        streetViewControl: false,
        scaleControl: true,
        zoomControl: true,
        zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER
        },
        styles: [
            {
                "featureType": "administrative.locality",
                "elementType": "all",
                "stylers": [
                    {
                        "lightness": "0"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "lightness": "100"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "hue": "#f3f4f4"
                    },
                    {
                        "saturation": -84
                    },
                    {
                        "lightness": 59
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "labels",
                "stylers": [
                    {
                        "hue": "#ff0000"
                    },
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": "100"
                    },
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "landscape.natural",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.attraction",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.business",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.government",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.medical",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "hue": "#adff00"
                    },
                    {
                        "saturation": "0"
                    },
                    {
                        "lightness": "-9"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "poi.place_of_worship",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.school",
                "elementType": "all",
                "stylers": [
                    {
                        "hue": "#00ffff"
                    },
                    {
                        "saturation": -60
                    },
                    {
                        "lightness": 23
                    },
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.sports_complex",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "lightness": "0"
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "hue": "#ff0000"
                    },
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 100
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels",
                "stylers": [
                    {
                        "hue": "#ff0000"
                    },
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 26
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                    {
                        "hue": "#ffcc00"
                    },
                    {
                        "saturation": 100
                    },
                    {
                        "lightness": "-10"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.highway.controlled_access",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                    {
                        "hue": "#ffcc00"
                    },
                    {
                        "saturation": 100
                    },
                    {
                        "lightness": "-15"
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "all",
                "stylers": [
                    {
                        "lightness": "0"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "hue": "#00b5ff"
                    },
                    {
                        "saturation": 55
                    },
                    {
                        "lightness": -6
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels",
                "stylers": [
                    {
                        "hue": "#7fc8ed"
                    },
                    {
                        "saturation": 55
                    },
                    {
                        "lightness": -6
                    },
                    {
                        "visibility": "off"
                    }
                ]
            }
        ]
    });

    //Markers
    var markers = [];
    const markerData = []; 

    //Geolocalisation
    var geolocalisationBtn = document.getElementById('geolocalisation');

    if(geolocalisationBtn){
        geolocalisationBtn.addEventListener('click', () => {
            if(navigator.geolocation){
                navigator.geolocation.getCurrentPosition(position => {
                    var pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    map.setCenter(pos);
                    map.setZoom(12);
                })
            }
        });
    }

    //Recherche
    var searchBoxContainer = document.getElementById('search-map');
    var searchBtn = document.getElementById('search-map-btn');
    var options = {
        componentRestrictions: {country: "fr"}
    };

    // Search labs input
    const params = {
        minChars: 3,
        endpoint: `${BASE_ENDPOINT}/suggestions`,
        selectors: {
            input: '[js-search-lab-input]',
            form: '[js-search-form]',
            button: '[js-search-button]',
            container: '[js-suggestions-container]',
            reset: '[js-search-lab-reset]'
        },
        classes: {
            show: 'show',
            element: 'element',
            group: 'group',
            name: 'name',
            labs: 'labs',
            lab: 'lab'
        }
    }
    const searchInput = document.querySelector(params.selectors.input);
    const searchReset = document.querySelector(params.selectors.reset);

    const handleSuggestions = async e => {
        const value = e.target?.value;
    
        const container = document.querySelector(params.selectors.container);
    
        if (value.length < params.minChars) {
            container.classList.remove(params.classes.show);
            removeSuggestions();
            return;
        }

        const suggestions = await handleRequest(value);

        removeSuggestions();
    
        if (suggestions.data.length > 0) {
            suggestions.data.forEach(group => {
                 createSuggestion(group, container);
            })
            container.classList.add(params.classes.show);
            hideOnClickOutside(container);
        }
    }

    
    const createSuggestion = (suggestion, list) => {
        if(!suggestion.laboratories.length) return;
        const group = document.createElement('div');
        group.classList.add(params.classes.group);

        const name = document.createElement('div');
        name.classList.add(params.classes.name);

        //Replace search value in name with <span>
        const regex = new RegExp(`(${searchInput.value})`, 'gi');
        name.innerHTML = 'Groupement ' + suggestion.title.replace(regex, `<b>$1</b>`);
    
        group.appendChild(name);
        const labs = document.createElement('div');
        labs.classList.add(params.classes.labs);
        suggestion.laboratories.forEach(lab => {
            const labElement = document.createElement('div');
            labElement.classList.add(params.classes.lab);
            labElement.innerHTML = lab.title.replace('Laboratoire ', '').replace(regex, `<b>$1</b>`);
            labs.appendChild(labElement);
            labElement.addEventListener('click',(e) => handleLabClick(parseFloat(lab.address.lat), parseFloat(lab.address.lng), suggestion.title, list));
        })
    
        group.appendChild(labs);
        list.appendChild(group);

        name.addEventListener('click', e => handleGroupClick(suggestion.id, suggestion.title, list));
    }
    
    const removeSuggestions = () => {
        const container = document.querySelector(params.selectors.container);
        container.innerHTML = '';
    }
    
    const handleRequest = async value => {
        try {
            const response = await cancelableFetch(params.endpoint, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({value})
            });
    
            return await response.json();
        } catch (error) {
            console.log(error)
        }
    }
   
    const handleGroupClick = (groupId, groupName, container) => {

        if (!groupId) return;

        //Hide suggestions 
        container && container.classList.remove(params.classes.show);
        if (groupName) {
             searchInput.value = decodeHtmlEntities(groupName);
        }

        displayGroup(groupId);
    }

    const displayGroup = (groupId) => {
          searchReset.classList.add(params.classes.show);
          searchReset.addEventListener('click', () => {
              searchReset.classList.remove(params.classes.show);
              searchInput.value = '';
              diplayAllMarkers();
              setTimeout(() => {
                map.setCenter(center);
                map.setZoom(zoom);
              }, 100);
          })
          //Clear markers and clusters
          markerCluster.clearMarkers();

          const visibleMarkers = [];
          const bounds = new google.maps.LatLngBounds();
    
          //Loop over markers and set visibility according to groupId
          markerData.forEach(markerObj => {
              if (markerObj.groupIds.includes(groupId)) {
                  markerObj.marker.setVisible(true); 
                  bounds.extend(markerObj.marker.getPosition()); 
                  visibleMarkers.push(markerObj.marker); 
              } else {
                  markerObj.marker.setVisible(false);
              }
          });
      
          //Update clusters
          markerCluster.addMarkers(visibleMarkers);
  
          setTimeout(() => {
               // trick to add margin to the bounds
                const ne = bounds.getNorthEast();
                const sw = bounds.getSouthWest();

                const margin = 0.1; 

                const extendedBounds = new google.maps.LatLngBounds(
                    new google.maps.LatLng(sw.lat() - margin, sw.lng() - margin),
                    new google.maps.LatLng(ne.lat() + margin, ne.lng() + margin)
                );

                map.fitBounds(extendedBounds);
          }, 100); 
    }
    
    const handleLabClick = (lat, lng, labName, container) => {
        container.classList.remove(params.classes.show);
        searchInput.value = labName;

        diplayAllMarkers();

        map.setZoom(14); 
        const marker = findMarkerByCoordinates(lat, lng);
        marker && google.maps.event.trigger(marker, 'click');
    }

    const diplayAllMarkers = () => {
        markerCluster.clearMarkers();

        const visibleMarkers = [];
  
        //Loop over markers and set visibility according to groupId
        markerData.forEach(markerObj => {
            markerObj.marker.setVisible(true);
            visibleMarkers.push(markerObj.marker); 
        });
    
        //Update clusters
        markerCluster.addMarkers(visibleMarkers);
    }
    
    const hideOnClickOutside = element => {
        const outsideClickListener = event => {
            if (!element.contains(event.target) && isVisible(element)) {
                element.classList.remove(params.classes.show);
                removeClickListener();
            }
        }
    
        const removeClickListener = () => {
            document.removeEventListener('click', outsideClickListener);
        }
    
        document.addEventListener('click', outsideClickListener);
    }
    
    const isVisible = element => !!element && !!( element.offsetWidth || element.offsetHeight || element.getClientRects().length );
    
    
    if (searchInput) {
        searchInput.addEventListener('input', handleSuggestions)
    }
    
    //Helpers
    const findClosestMarker = (event) => {
        var distances = [];
        var closest = -1;
        for (i = 0; i < markers.length; i++) {
            var d = google.maps.geometry.spherical.computeDistanceBetween(markers[i].position, event);
            distances[i] = d;
            if (closest == -1 || d < distances[closest]) {
                closest = i;
            }
        }
        return markers[closest].getPosition();
    }

    const findMarkerByCoordinates = (lat, lng) => {
        return markerData.find(markerObj => markerObj.lat === lat && markerObj.lng === lng)?.marker || null;
    };


    const findMarkerByLabId = (id) => {
        return markerData.find(markerObj => markerObj.labId === id)?.marker || null;
    };

    const getUrlParameter = (name) => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(name);
    }

    // Trick to escape HTML special chars
    const decodeHtmlEntities = (str) => {
        const txt = document.createElement("textarea");
        txt.innerHTML = str;
        return txt.value;
    }

    if(searchBoxContainer){
        var searchBox = new google.maps.places.Autocomplete(searchBoxContainer, options);

        searchBoxContainer.addEventListener('keypress', (e) => {
            if(e.keyCode == '13' && e.target.value.length > 0){
                var place = searchBox.getPlace();
                var bounds = new google.maps.LatLngBounds();
                var nearest = findClosestMarker(place.geometry.location);

                if (!place.geometry) {
                    console.log("Returned place contains no geometry");
                    return;
                }

                bounds.extend(place.geometry.location);
                bounds.extend(nearest);
                map.fitBounds(bounds);
                map.setZoom(13);
            }
        });

        searchBtn.addEventListener('click', () => {
            var place = searchBox.getPlace();
            var bounds = new google.maps.LatLngBounds();

            var nearest = findClosestMarker(place.geometry.location);

            if (!place.geometry) {
                console.log("Returned place contains no geometry");
                return;
            }

            bounds.extend(place.geometry.location);
            bounds.extend(nearest);
            map.fitBounds(bounds);
            map.setZoom(13);
        });

        map.addListener('bounds_changed', function() {
            searchBox.setBounds(map.getBounds());
        });
    }

    //Populate markers
    for(var i = 0; i < window.labsMarkers.length; i++){
        var marker = new google.maps.Marker({
            position: {lat: parseFloat(window.labsMarkers[i].address.lat), lng: parseFloat(window.labsMarkers[i].address.lng)},
            map: map,
            icon: '../themes/LBIMainSite/static/img/marker.png',
            title: window.labsMarkers[i].name.replace('&rsquo;', '\'')
        });
        markers.push(marker);
        markerData.push({
            marker: marker,
            labId: window.labsMarkers[i].id,
            lat: parseFloat(window.labsMarkers[i].address.lat),
            lng: parseFloat(window.labsMarkers[i].address.lng),
            groupIds: window.labsMarkers[i].groupIds
        });

        var content = '';

        var infoWindow = null;
        var prevInfowindow = null;

        var prevMarker = null;

        var offsetCenter = 5;

        if(window.matchMedia("(max-width:720px)").matches){
            content = '<div class="infowindow">'+
                '<i class="icon-close" id="close-info"></i>'+
                '<p class="infowindow__title">'+window.labsMarkers[i].name+'</p>'+
                '<div class="infowindow__address"><i class="icon-place"></i>'+window.labsMarkers[i].address.address+'</div>'+
                '<div class="infowindow__numbers"><div class="infowindow__phone"><i class="icon-phone"></i>'+window.labsMarkers[i].phone+'</div><div class="infowindow__fax"><i class="icon-fax"></i>'+window.labsMarkers[i].fax+'</div></div>'+
                '<div class="infowindow__cta"><div class="infowindow__cta--fiche"><a href="/nos-laboratoires/'+ window.labsMarkers[i].slug +'" class="btn">Voir la fiche</a></div><div class="infowindow__cta--rdv"><a href="" class=""><i class="icon-date"></i>Prendre RDV</a></div></div>'+
                '</div>';
        }else{
            content = '<div class="infowindow">'+
                '<p class="infowindow__title">'+window.labsMarkers[i].name+'</p>'+
                '<div class="infowindow__address"><i class="icon-place"></i>'+window.labsMarkers[i].address.address+'</div>'+
                '<div class="infowindow__numbers"><div class="infowindow__phone"><i class="icon-phone"></i>'+window.labsMarkers[i].phone+'</div><div class="infowindow__fax"><i class="icon-fax"></i>'+window.labsMarkers[i].fax+'</div></div>'+
                '<div class="infowindow__cta"><div class="infowindow__cta--fiche"><a href="/nos-laboratoires/'+ window.labsMarkers[i].slug +'" class="btn">Voir la fiche</a></div><div class="infowindow__cta--rdv"><a href="tel:'+ window.labsMarkers[i].phone +'" class=""><i class="icon-date"></i>Prendre RDV</a></div></div>'+
                '</div>';
        }

        google.maps.event.addListener(marker,'click', (function(marker,content,infoWindow){
            return function() {

                infoWindow = new google.maps.InfoWindow();

                google.maps.event.addListener(infoWindow, 'closeclick', (function(marker,content,infoWindow){
                    return function() {
                        marker.setIcon('../themes/LBIMainSite/static/img/marker.png');
                    };
                })(marker,content,infoWindow));

                if(window.matchMedia("(max-width:720px)").matches){
                    if (prevInfowindow) {
                        document.querySelector('.infowindow--mobile').style.opacity = 0;
                        document.querySelector('.infowindow--mobile').style.visibility = 'hidden';
                        document.querySelector('.infowindow--mobile').innerHTML = '';
                        prevMarker.setIcon('../themes/LBIMainSite/static/img/marker.png');
                    }
                    marker.setIcon('../themes/LBIMainSite/static/img/bigmarker.png');
                    document.querySelector('.infowindow--mobile').style.visibility = 'visible';
                    document.querySelector('.infowindow--mobile').style.opacity = 1;
                    document.querySelector('.infowindow--mobile').innerHTML += content;
                    document.getElementById('close-info').addEventListener('click', () => {
                        document.querySelector('.infowindow--mobile').style.opacity = 0;
                        document.querySelector('.infowindow--mobile').style.visibility = 'hidden';
                        document.querySelector('.infowindow--mobile').innerHTML = '';
                        marker.setIcon('../themes/LBIMainSite/static/img/marker.png');
                    });
                    map.setCenter(marker.getPosition());
                    prevInfowindow = infoWindow;
                    prevMarker = marker;
                }else{
                    if (prevInfowindow) {
                        prevInfowindow.close();
                        prevMarker.setIcon('../themes/LBIMainSite/static/img/marker.png');
                    }
                    marker.setIcon('../themes/LBIMainSite/static/img/bigmarker.png');
                    infoWindow.setContent(content);
                    infoWindow.open(map, marker);
                    map.setCenter({lat: marker.getPosition().lat(), lng: marker.getPosition().lng()});
                    map.panBy(0, -100);
                    prevInfowindow = infoWindow;
                    prevMarker = marker;
                }
            };
        })(marker,content,infoWindow));

        google.maps.event.addListener(map,'click', (function(marker, infoWindow){
            return function() {
                if(window.matchMedia("(max-width:720px)").matches){
                    document.querySelector('.infowindow--mobile').style.opacity = 0;
                    document.querySelector('.infowindow--mobile').innerHTML = '';
                    marker.setIcon('../themes/LBIMainSite/static/img/marker.png');
                }else{
                    if(prevInfowindow){
                        prevInfowindow.close(map,marker);
                    }
                    marker.setIcon('../themes/LBIMainSite/static/img/marker.png');
                }
            };
        })(marker, infoWindow));

    }

    var clusterStyles = [
        {
            textColor: 'white',
            textSize: 18,
            url: '../themes/LBIMainSite/static/img/cluster.png',
            height: 46,
            width: 46
        },
        {
            textColor: 'white',
            textSize: 18,
            url: '../themes/LBIMainSite/static/img/cluster.png',
            height: 46,
            width: 46
        },
        {
            textColor: 'white',
            textSize: 18,
            url: '../themes/LBIMainSite/static/img/cluster.png',
            height: 46,
            width: 46
        }
    ];

    var mcOptions = {
        gridSize: 50,
        styles: clusterStyles,
        maxZoom: 15
    };

    var markerCluster = new MarkerClusterer(map, markers, mcOptions);

     // Retrieve 'g' parameter from the URL
     const groupId = getUrlParameter('g');
     setTimeout(() => {
          groupId && displayGroup(parseInt(groupId)); 
     }, 100);

     const labId = getUrlParameter('l');
     setTimeout(() => {
        if (labId) {
            map.setZoom(14); 
            const marker = findMarkerByLabId(parseInt(labId));
            marker && google.maps.event.trigger(marker, 'click');
        }
     }, 100);
   
};
